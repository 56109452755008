/**
 * VALUES
 * Permet de gérer les values des éléments qui utilise la structure ElementValue
 * js-values-container : Container pour 1 element qui contient plusieurs contenus
 */

const containerValuesContents = document.querySelectorAll(".js-values-container");

const defineElementValueEvents = (container, element) => {
    const btnDelete = element.querySelector("button.js-btn-delete-value");

    if (btnDelete) {
        btnDelete.addEventListener("click", (e) => {
            e.preventDefault();
            // On supprime tous les éléments qui ont le meme id (Pour les autres langues)
            const elements = container.querySelectorAll(`#${element.id}`);
            elements.forEach(element => {
                element.remove();
            });
        });
    }

    const inputName = element.querySelector("input[name*='name']");
    const button = element.querySelector("button.hs-accordion-toggle span.js-title");
    inputName.addEventListener("input", (e) => {
        const value = e.target.value;
        button.innerHTML = value;
    });    
};

const createValueElement = (container, options = {}) => {
    const cloneContents = container.querySelector(".js-values-clone");
    const wantFocus = options.hasOwnProperty("focus") ? options.focus : true;

    const element = document.createElement("li");
    element.classList.add("js-values-items","hs-accordion","accordions__items");
    let randomId = 'value-'+ Math.floor(Math.random() * 1000) + '-'+ Math.floor(Math.random() * 1000);
    element.setAttribute("id", randomId);

    const button = document.createElement("button");
    button.classList.add("hs-accordion-toggle","accordions__head","accordions__head-outline");
    button.setAttribute("type", "button");
    button.setAttribute("aria-expanded", "false");
    button.setAttribute("aria-controls", randomId + "-collapse");
    const btnName = options.hasOwnProperty("name") ? options.name : "";
    button.innerHTML = `
        <div class="flex gap-2"><span class="js-type">(${type})</span><span class="js-title">${btnName}</span></div><i class="hs-accordion-active:rotate-180 fa-solid fa-chevron-down transition-all"></i>
    `;

    // On clone le contenu
    const div = cloneContents.cloneNode(true);
    div.classList.remove("hidden");
    div.setAttribute("id", randomId + "-collapse");
    div.setAttribute("aria-labelledby", randomId);

    element.appendChild(button);
    element.appendChild(div);

    // On selectionne le "name"
    const inputName = div.querySelector("input[name*='name']");
    inputName.value = options.hasOwnProperty("name") ? options.name : "";

    // On change la valeur du name des inputs
    const inputs = div.querySelectorAll("input[name*='clone'], .field__textarea");
    inputs.forEach(input => {
        const name = input.getAttribute("name");
        if (name) {
            input.setAttribute("name", name.replace("clone", 'values['+totalElements+']'));
        }else{
            const dataName = input.getAttribute("data-name");   
            input.setAttribute("data-name", dataName.replace("clone", 'values['+totalElements+']'));
        }
    });
    // On ajoute les events
    defineElementValueEvents(container, element);

    // On ajoute l'élément dans le container
    i = 0;
    const containerFieldsLanguages = container.querySelectorAll(".js-values-list-fields");
    containerFieldsLanguages.forEach(containerLanguage => {
        if (i == 0) {
            containerLanguage.appendChild(element);
            if ((!container.classList.contains("hidden") || container.closest(".hidden") === null) && wantFocus) {
                element.querySelector("button").click();
                element.querySelector("input[name*='name']").focus();
            }
        }else{
            // On clone l'élément
            const cloneElement = element.cloneNode(true);
            containerLanguage.appendChild(cloneElement);
            if ((!container.classList.contains("hidden") || container.closest(".hidden") === null) && wantFocus) {
                cloneElement.querySelector("button").click();
                cloneElement.querySelector("input[name*='name']").focus();
            }
        }
        i++;
    });

    const textareas = container.querySelectorAll(".field__textarea.js-editor-not-loaded");
    textareas.forEach(textarea => {
        // ne rien faire si textArea dans productContentClone
        if (!textarea.getAttribute("data-name").includes("clone")) {
            textarea.classList.remove("js-editor-not-loaded");
            textarea.classList.add("js-editor");

            textarea.setAttribute("id", "editor-" + Math.floor(Math.random() * 1000) + "-" + Math.floor(Math.random() * 1000));
            //Global Method
            TextEditor.new(textarea);
        }
    });
};

containerElementsContents.forEach(container => {
    const btnAddContent = container.querySelectorAll("button.js-btn-add-content");

    const existingElements = container.querySelectorAll(".js-values-list .js-values-items");
    existingElements.forEach(element => {
        defineElementValueEvents(container, element);
    });
        
    btnAddContent.forEach(btn => {
        btn.addEventListener("click", async (e) => {
            // On crée un nouvelle élément dans la list des contenus
            e.preventDefault();
            const haveContent = container.querySelector(".js-values-list .js-values-items");
            if (!haveContent) {
                createValueElement(container , {
                    'name' : 'exemple',
                    'code' : 'exemple',
                    'focus' : true,
                });
            }else{
                createValueElement(container, {
                    'focus' : true,
                });
            }
        });
    });
});


